import React, { useEffect, useState } from 'react';
import css from './CustomLandingPage.module.css';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import { LayoutSingleColumn, ListingCard, Page } from '../../components';
import HeroSection from './components/HeroSection/HeroSection';
import { getListings } from './utils';
import BoxesSlider from './components/BoxesSlider/BoxesSlider';
import SectionAbout from './components/SectionAbout/SectionAbout';
import SectionProductRange from './components/SectionProductRange/SectionProductRange';
import WhyGroundz from './components/WhyGroundz/WhyGroundz';

const CustomLandingPageComponent = props => {
  const { scrollingDisabled } = props;

  const [dealsOfTheDay, setDealsOfTheDay] = useState([]);

  useEffect(() => {
    //get deals Of The Day
    getListings({})
      .then(resp => {
        setDealsOfTheDay(resp);
      })
      .catch(e => console.log(e));
  }, []);

  const deals = dealsOfTheDay.map(l => {
    if (!l) {
      return null;
    }

    const renderSizes = [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');
    return (
      <ListingCard
        className={css.listingCard}
        key={l.id.uuid}
        listing={l}
        renderSizes={renderSizes}
        setActiveListing={() => {}}
        isLandingPage={true}
      />
    );
  });
  const showDealsOfTheDay = !!dealsOfTheDay.find(d => d);

  return (
    <Page title={'Grounddz'} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
        <div className={css.wrapper}>
          <HeroSection />
          <SectionAbout />
          <SectionProductRange />
          {showDealsOfTheDay && (
            <div className={css.sectionWrapper}>
              <h2 className={css.sectionTitle}>What’s New</h2>
              <BoxesSlider slides={deals} />
            </div>
          )}
          <WhyGroundz />
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const CustomLandingPage = compose(connect(mapStateToProps))(CustomLandingPageComponent);

export default CustomLandingPage;
