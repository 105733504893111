import React from 'react';
import css from './SectionAbout.module.css';

const SectionAbout = () => {
  return (
    <div className={css.root}>
      <div className={css.container}>
        <div className={css.heading}>About Us</div>
        <h2>Revolutionizing martial arts commerce in the UK</h2>
        <div className={css.content}>
          <div className={css.contentItem}>
            <p>
              In the dynamic world of sports, Grounddz emerges as the ultimate marketplace for
              martial arts enthusiasts in the UK. With a commitment to providing top-tier equipment,
              both brand new and used, Grounddz caters to the needs of sports enthusiasts who are
              passionate about their martial arts journey.
            </p>
            <p>
              Our platform goes beyond being a mere marketplace; it's a community hub where sports
              enthusiasts unite. Whether you're a seasoned martial artist or a beginner taking the
              first steps in your journey, Grounddz welcomes you. We foster a sense of community by
              not only providing exceptional products but also creating a space for knowledge
              sharing, discussions, and updates on the latest trends in the martial arts world.
            </p>
          </div>
          <div className={css.contentItem}>
            <p>
              What sets Grounddz apart is our dedication to customer satisfaction. Whether you're in
              search of gloves, protective gear, training equipment, or accessories, you can trust
              Grounddz to deliver excellence.
            </p>
            {/* <br /> */}
            <p>
              At Grounddz, we recognize the importance of affordability and sustainability. Grounddz
              proudly offers a selection of high-quality used martial arts equipment, allowing
              enthusiasts to access premium gear at a more budget-friendly cost. This not only
              supports the financial well-being of our customers but also promotes a responsible
              approach to consumption. Grounddz is not just a marketplace; it's a destination where
              sports enthusiasts find the tools they need, connect with like-minded individuals, and
              stay informed about the exciting developments in the world of martial arts. Your
              journey begins here at Grounddz, where we are dedicated to being your premier
              destination for martial arts equipment in the UK.
            </p>
          </div>
          {/* <div className={css.contentItem}></div>
          <div className={css.contentItem}></div> */}
        </div>
      </div>
    </div>
  );
};

export default SectionAbout;
