import React, { useRef } from 'react';
import { UilAngleRightB } from '@iconscout/react-unicons';
import { UilAngleLeftB } from '@iconscout/react-unicons';
import css from './BoxesSlider.module.css';

const BoxesSlider = ({ slides }) => {
  const sliderRef = useRef(null);

  const scrollToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: sliderRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  const scrollToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -sliderRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={css.sliderContainer}>
      <div className={css.sliderContent} ref={sliderRef}>
        {slides.map((slide, index) => (
          <div className={css.slide} key={index}>
            {slide}
          </div>
        ))}
      </div>
      <button className={css.prevButton} onClick={scrollToPrevSlide}>
        <UilAngleLeftB className={css.icon} />
      </button>
      <button className={css.nextButton} onClick={scrollToNextSlide}>
        <UilAngleRightB className={css.icon} />
      </button>
    </div>
  );
};

export default BoxesSlider;
