import React from 'react';
import css from './SectionProductRange.module.css';
import Section from './Section';
import boxingPad3 from '../../../../assets/sectionImages/Boxinb pads 3.webp';
import boxingPad1 from '../../../../assets/sectionImages/Boxing pads.jpeg';
import boxingPad2 from '../../../../assets/sectionImages/Boxing pads 2.jpeg';
import rashGuard from '../../../../assets/sectionImages/boxing wrap.webp';
import belt from '../../../../assets/sectionImages/bjj belt.jpeg';
import headGair from '../../../../assets/sectionImages/boxing headgear.jpeg';
import glovesOne from '../../../../assets/sectionImages/glovesOne.avif';
import glovesTwo from '../../../../assets/sectionImages/glovesTwo.jpg';
import komokiaOne from '../../../../assets/sectionImages/komokiaOne.jpg';
import komokiaTwo from '../../../../assets/sectionImages/komokaTwo.jpg';
import rashGuardOne from '../../../../assets/sectionImages/rashGuard.jpg';
const SectionProductRange = () => {
  const data = [
    {
      heading: 'Boxing Gloves: High-Intensity Training and Fighting Gear',
      text:
        'Our boxing gloves cater to modern fighters, prioritizing quality and performance. Essential for hand protection during training and competition, they are made from durable materials, available in various sizes, and suitable for athletes of all levels. Offering support and enhancing striking power, our gloves ensure a safer workout, allowing athletes, whether amateur enthusiasts or professionals, to perform at their best while minimizing impact on their hands.',
      images: [glovesOne, glovesTwo],
      cardHeading: 'Shop Our Boxing Gloves Collection ',
      link: '?pub_product=boxing_gloves',
    },
    {
      heading: 'Boxing Pads: Maximum Impact Absorption for Superior Training',
      text:
        'Enhance your striking skills with the collection of boxing pads. These pads are essential for honing accuracy, speed, and power in punches and kicks. Crafted for maximum impact absorption, they reduce the risk of injury and ensure a safer training environment for both athletes and coaches. The range includes various designs to suit different training needs, from focus mitts to Thai pads, all made with durable materials to withstand rigorous use.',
      images: [boxingPad1, boxingPad2, boxingPad3],
      cardHeading: 'Shop Our Boxing Pads Range',
      link: '?pub_product=boxing_pads',
    },
    {
      heading: 'Kimonos (GI’s): Elegance and Durability for Your Judo,Karate  & BJJSessions',
      text:
        'The kimono is more than just a piece of clothing; it s a symbol of tradition and respect in martial arts like Judo, Karate & Brazilian Jiu Jitsu. The range of kimonos or BJJ Gi’s combines elegance with durability, ensuring that they stand up to the rigors of training and competition. Available in various sizes and styles, the kimonos are designed to offer maximum comfort and mobility, allowing you to focus on your technique and performance. Embrace the martial arts tradition with a kimono that reflects your dedication and respect for the art.',
      images: [komokiaOne, komokiaTwo],
      cardHeading: 'Shop Our Kimono & BJJ GI Collection ',
      link: '?pub_product=gis',
    },
    {
      heading: 'Rashguards: Comfort and Protection for Every Fight',
      text:
        'The range of rashguards is designed for the martial artist who values both comfort and protection. Ideal for grappling sports like BJJ and MMA, these rashguards from various brands are made with high-quality, durable fabrics that withstand intense training sessions. They offer a snug fit to prevent chafing and irritation while ensuring full range of motion. With moisture-wicking properties and reinforced stitching, the rashguards are built to last and keep you focused on your training.',
      images: [rashGuardOne],
      cardHeading: 'Shop Our Rashguard Collection',
      link: '?pub_product=rashguards',
    },
    {
      heading: 'Boxing Accessories: All You Need for Your Gym',
      text:
        'To complement your training, we offer a comprehensive range of boxing accessories. This includes everything from hand wraps to headgear and protective gear. The accessories assure you quality and durability, ensuring you have the best tools to support your training regimen. Whether you are gearing up for sparring sessions or focusing on technique improvement, our accessories will help you train effectively and safely.',
      images: [rashGuard, belt, headGair],
      cardHeading: 'Explore Our Boxing Accessories Range',
      link: '?pub_product=boxing_shoes%2Cboxing_short%2Cboxing_timer%2Cboxing_wraps_and_tape',
    },
  ];
  return (
    <div className={css.root}>
      <div className={css.container}>
        <div className={css.heading}>
          <h2>Our Product Range</h2>
        </div>
        <p className={css.subHeading}>
          At Grounddz, we believe in equipping you with the best. We understand that having the
          right equipment is essential for any martial artist. Our product range is carefully
          curated to meet the diverse needs of various martial arts disciplines, ensuring that you
          have access to the best gear for your training and competition needs. Explore our
          selection and find the perfect match for your martial arts journey.
        </p>
        <Section {...data[0]} />
        <Section {...data[1]} />
        <Section {...data[2]} />
        <Section {...data[3]} />
        <div className={css.heading}>
          <h2>Train Like a Pro</h2>
        </div>
        <p className={css.subHeading}>
          Grounddz is dedicated to equipping martial artists with the tools they need to train at a
          professional level. Our selection of training equipment is designed to enhance your
          strength, precision, and technique, whether you're a seasoned fighter or just starting
          your journey.
        </p>
        <Section {...data[4]} />
      </div>
    </div>
  );
};

export default SectionProductRange;
