import React from 'react';
import css from './SectionProductRange.module.css';
import { NamedLink } from '../../../../components';

import { Carousel } from 'react-responsive-carousel';
const Section = props => {
  const { heading, text, images, link, cardHeading } = props;

  return (
    <div className={css.section}>
      <div className={css.first}>
        <h3>{heading}</h3>
        <p>{text}</p>
      </div>

      <Carousel
        autoPlay={true}
        showStatus={false}
        showThumbs={false}
        showArrows={true}
        showIndicators={false}
        infiniteLoop={true}
        swipeable={true}
        className={css.second}
        transitionTime={1000}
        interval={2000}
      >
        {images?.map((image, index) => (
          <div key={index} className={css.slideContent}>
            <img src={image} alt="product" className={css.image} />

            <div className={css.buttonContainer}>
              {cardHeading ? <h3 className={css.cardHeading}>{cardHeading}</h3> : null}
              <NamedLink
                name="SearchPage"
                to={{
                  search: link,
                }}
                className={css.button}
              >
                Shop now
              </NamedLink>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Section;
