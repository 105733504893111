import React from 'react';
import css from './WhyGroundz.module.css';
import image2 from '../Images/Bottom-2.png';
import image3 from '../Images/bottom-3.png';
import image4 from '../Images/Bottom-4.png';
const WhyGroundz = () => {
  const images = [
    { image: image2, text: 'SECURE PAYMENTS' },
    { image: image3, text: 'GOOD DEALS' },
    { image: image4, text: 'ECO- FRIENDLY' },
  ];
  return (
    <div className={css.root}>
      <div className={css.container}>
        <h2>Why Choose Grounddz?</h2>
        <p>
          Finding affordable sports equipment online is still a challenge! That's where Grounddz
          comes in. We provide a trusted space for the sports community to access budget friendly
          sports gear, inspiring people to continue or restart their sports journeys. Choosing
          Grounddz means you're contributing to a greener planet and a healthier environment. Plus,
          you can earn extra income by selling. Join us in shaping a better future! Choosing
          Grounddz means choosing a partner dedicated to supporting your martial arts journey with
          the best gear available. Experience the difference with Grounddz – where quality,
          reliability, and customer satisfaction are at the heart of everything we do.
        </p>
        <div className={css.containerIcon}>
          {images.map((image, index) => (
            <div className={css.bodyContainer} key={index}>
              <img key={index} src={image.image} alt="Why" className={css.image} />
              <p className={css.text}>{image.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyGroundz;
